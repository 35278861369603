var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "header-tab-flight-id"
    }
  }, [_c('swiper', {
    staticClass: "swiper",
    attrs: {
      "options": _vm.swiperOptions
    }
  }, _vm._l(_vm.getSearchFlightArray, function (trip, tripIndex) {
    return _c('swiper-slide', {
      key: tripIndex,
      staticClass: "slide fit-content"
    }, [_c('BButton', {
      staticClass: "p-75 mb-25 rounded-8px text-left d-flex-center align-items-start flex-column",
      style: _vm.tabIndex === tripIndex ? 'background: #166987; color: white' : 'color: #166987; background: white; border: 1px solid #166987;',
      attrs: {
        "variant": "vna-color"
      },
      on: {
        "click": function click($event) {
          return _vm.handleClickTab(tripIndex);
        }
      }
    }, [_c('span', {
      staticClass: "fw-700 text-16px"
    }, [_vm._v(" #" + _vm._s(tripIndex + 1) + " " + _vm._s("".concat(trip.startPoint, "-").concat(trip.endPoint, " ").concat(_vm.convertISODateTime(trip.departDate).date)) + " ")]), _c('span', {
      staticClass: "text-14px font-weight-light"
    }, [_vm.selectedTrips[tripIndex] ? _c('span', [_vm._v(" " + _vm._s("".concat(_vm.resolveAirlineFlightNumber(_vm.selectedTrips[tripIndex].airline, _vm.selectedTrips[tripIndex].flightNumber))) + " " + _vm._s(_vm.convertISODateTime(_vm.selectedTrips[tripIndex].departure.at, _vm.selectedTrips[tripIndex].departure.timezone).time) + " - " + _vm._s(_vm.convertISODateTime(_vm.selectedTrips[tripIndex].arrival.at, _vm.selectedTrips[tripIndex].arrival.timezone).time) + " ")]) : _c('span', {
      staticClass: "text-warning"
    }, [_vm._v(" " + _vm._s(_vm.$t('flight.notSelectedFlights')) + " ")])])])], 1);
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }